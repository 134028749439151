import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Flex,
  Box,
  IconButton,
  Spacer,
  Link,
  Stack,
  Text,
  ButtonGroup,
  useDisclosure,
  Drawer,
  DrawerOverlay,
} from "@chakra-ui/react";
import { HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import { Link as GatsbyLink } from "gatsby";
import "@fontsource/lora";

import SideMenu from "./SideMenu";
import SearchModal from "./SearchModal";

const elementScroll = (element) => {
  const y = element.getBoundingClientRect().top + window.pageYOffset + -100;

  window.scrollTo({ top: y, behavior: "smooth" });
};

const getDimensions = (ele) => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const AppBar = (props) => {
  const {
    main,
    refs,
    lunrIndex,
    speciesOptions,
    categoryOptions,
    submissionTypeOptions,
  } = props;
  const {
    speciesRef,
    aboutRef,
    popularArticlesRef,
    recentArticlesRef,
    authorsRef,
    reviewersRef,
    contactRef,
    partnersRef,
    publishRef,
    newsRef,
  } = refs || {};
  const [showMenu, setShowMenu] = useState(false);
  //  const [scrolled, setScrolled] = useState(!main);
  const [visibleSection, setVisibleSection] = useState();
  const btnRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const sectionRefs = [
      { section: "Species", ref: speciesRef },
      { section: "About", ref: aboutRef },
      { section: "Popular", ref: popularArticlesRef },
      { section: "Recent Articles", ref: recentArticlesRef },
      { section: "For Authors", ref: authorsRef },
      { section: "For Reviewers", ref: reviewersRef },
      { section: "Contact", ref: contactRef },
      { section: "Partners", ref: partnersRef },
      { section: "Publish", ref: publishRef },
      { section: "News", ref: newsRef },
    ];

    const handleScroll = () => {
      // const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + 200; //headerHeight;

      const selected = sectionRefs.find(({ ref }) => {
        if (!ref) return null;
        const ele = ref.current;

        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
        return null;
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    visibleSection,
    speciesRef,
    aboutRef,
    popularArticlesRef,
    recentArticlesRef,
    authorsRef,
    reviewersRef,
    contactRef,
    partnersRef,
    publishRef,
    newsRef,
  ]);

  return (
    <>
      <Flex
        w="100%"
        pos="fixed"
        backgroundColor="#f9f9f9"
        top={0}
        padding={3}
        shadow="md"
        zIndex={10}
      >
        <Stack direction="row">
          <IconButton
            icon={<HamburgerIcon />}
            onClick={toggleMenu}
            variant="outline"
          />
          <GatsbyLink to="/">
            <Text fontFamily="Lora" color="#26428b" fontSize="3xl">
              &micro;P
            </Text>
          </GatsbyLink>
        </Stack>
        <Spacer />

        {!main && (
          <GatsbyLink to="/">
            <Text fontFamily="Lora" color="#26428b" fontSize="3xl">
              microPublication Biology
            </Text>
          </GatsbyLink>
        )}

        {main && (
          <>
            <ButtonGroup variant="outline" isAttached>
              <Button
                onClick={() =>
                  recentArticlesRef &&
                  recentArticlesRef.current &&
                  elementScroll(recentArticlesRef.current)
                }
                isActive={visibleSection === "Recent Articles"}
              >
                Recent
              </Button>
              <Button
                onClick={() =>
                  popularArticlesRef &&
                  popularArticlesRef.current &&
                  elementScroll(popularArticlesRef.current)
                }
                isActive={visibleSection === "Popular"}
              >
                Popular
              </Button>
              <Button
                onClick={() =>
                  speciesRef &&
                  speciesRef.current &&
                  elementScroll(speciesRef.current)
                }
                isActive={visibleSection === "Species"}
              >
                Species
              </Button>
            </ButtonGroup>
            <Spacer />
            <ButtonGroup variant="outline" isAttached>
              <Button
                onClick={() =>
                  newsRef && newsRef.current && elementScroll(newsRef.current)
                }
                isActive={visibleSection === "News"}
              >
                News
              </Button>
              <Button
                onClick={() =>
                  aboutRef &&
                  aboutRef.current &&
                  elementScroll(aboutRef.current)
                }
                isActive={visibleSection === "About"}
              >
                About
              </Button>

              <Button
                onClick={() =>
                  authorsRef &&
                  authorsRef.current &&
                  elementScroll(authorsRef.current)
                }
                isActive={visibleSection === "For Authors"}
              >
                For Authors
              </Button>
              <Button
                onClick={() =>
                  reviewersRef &&
                  reviewersRef.current &&
                  elementScroll(reviewersRef.current)
                }
                isActive={visibleSection === "For Reviewers"}
              >
                For Reviewers
              </Button>
              <Button
                onClick={() =>
                  partnersRef &&
                  partnersRef.current &&
                  elementScroll(partnersRef.current)
                }
                isActive={visibleSection === "Partners"}
              >
                Partners
              </Button>
              <Button
                onClick={() =>
                  contactRef &&
                  contactRef.current &&
                  elementScroll(contactRef.current)
                }
                isActive={visibleSection === "Contact"}
              >
                Contact
              </Button>
            </ButtonGroup>
          </>
        )}

        <Spacer />
        {lunrIndex && (
          <Box pr={5}>
            <IconButton
              icon={<SearchIcon />}
              variant="outline"
              onClick={onOpen}
            />
            <Drawer
              isOpen={isOpen}
              placement="top"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />

              <SearchModal lunrIndex={lunrIndex} onClose={onClose} />
            </Drawer>
          </Box>
        )}
        <Box>
          <Button size="sm" colorScheme="teal">
            <Link
              textDecoration="none"
              href="https://portal.micropublication.org"
              isExternal
            >
              Submit a microPublication
            </Link>
          </Button>
        </Box>
      </Flex>

      <SideMenu
        setVisible={toggleMenu}
        visible={showMenu}
        speciesOptions={speciesOptions}
        categoryOptions={categoryOptions}
        submissionTypeOptions={submissionTypeOptions}
      />
    </>
  );
};

AppBar.propTypes = {
  menuAction: PropTypes.func,
  loading: PropTypes.bool,
  handleResultSelect: PropTypes.func,
  handleSearchChange: PropTypes.func,
  searchResults: PropTypes.arrayOf(PropTypes.object),
  searchValue: PropTypes.string,
};

AppBar.defaultProps = {
  menuAction: null,
  loading: false,
  handleResultSelect: null,
  handleSearchChange: null,
  searchResults: null,
  searchValue: null,
};

export default AppBar;
