import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Icon,
  InputGroup,
  Input,
  LinkBox,
  LinkOverlay,
  Heading,
  Text,
  InputRightElement,
  Image,
  DrawerCloseButton,
  DrawerHeader,
  DrawerContent,
  DrawerBody,
  WrapItem,
  Wrap,
} from "@chakra-ui/react";
import { Markup } from "interweave";
import { Index } from "lunr";
import { MdKeyboardReturn } from "react-icons/md";

const SearchModal = (props) => {
  const { initialFocusRef, lunrIndex } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setResults] = useState([]);

  const index = Index.load(lunrIndex.index);
  const searchData = useCallback(
    (e) => {
      setResults([]);

      const query = typeof e === "string" ? e : searchTerm;
      const results = index
        .search(`+${query.replace("-", "\\-").split(" ").join(" +")}`)
        .map(({ ref }) => {
          return {
            slug: ref,
            ...lunrIndex.store[ref],
          };
        });

      setResults(results);
    },
    [searchTerm, index, lunrIndex]
  );

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        setSearchTerm(event.target.value);
        searchData(event.target.value);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [searchData]);

  const updateQuery = (e) => {
    setSearchTerm(e.target.value);
    setResults([]);
  };

  return (
    <DrawerContent>
      <DrawerHeader>
        <Box width="50%">
          <InputGroup>
            <Input
              placeholder="Search microPublications..."
              id="Search"
              value={searchTerm}
              onChange={updateQuery}
              ref={initialFocusRef}
            />

            <InputRightElement
              cursor="pointer"
              onClick={searchData}
              children={<Icon as={MdKeyboardReturn} />}
            />
          </InputGroup>
        </Box>
        <DrawerCloseButton />
      </DrawerHeader>
      <DrawerBody>
        <Box height="75vh">
          <Box>Number of articles: {searchResults.length}</Box>
          <Wrap>
            {searchResults.map((item) => {
              const { image, slug, body, title, authors } = item;
              const href = image
                ? `/journals/biology/${slug.toLowerCase()}`
                : slug;

              const index =
                body && body.toLowerCase().indexOf(searchTerm.toLowerCase());

              return (
                <WrapItem>
                  <LinkBox
                    as="article"
                    borderWidth="1px"
                    height="md"
                    rounded="md"
                    _hover={{ border: "1px solid" }}
                    width="sm"
                  >
                    {item.image && (
                      <Image
                        src={image}
                        objectFit="cover"
                        overflow="hidden"
                        fit="cover"
                        height={200}
                        width="100%"
                      />
                    )}
                    <Box p="6">
                      <Heading size="md" my="2">
                        <LinkOverlay href={href}>
                          <Markup content={title} />
                        </LinkOverlay>
                      </Heading>
                      <Text isTruncated>
                        {authors}
                        {index && (
                          <>{`...${body.substring(
                            index - 20,
                            index + 20
                          )}...`}</>
                        )}
                      </Text>
                    </Box>
                  </LinkBox>
                </WrapItem>
              );
            })}
          </Wrap>
        </Box>
      </DrawerBody>
    </DrawerContent>
  );
};

export default SearchModal;
